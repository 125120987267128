import * as React from "react";
import Header from "../components/MarketingHeader";
import Achieva from "../images/Marketing/achieva-dark.svg";
import AchievaLogo from "../images/Clients/Achieva.jpg";
import KratosLogo from "../images/Clients/Kratos.jpg";
import CallectiveLogo from "../images/Clients/Callective.jpg";
import RedCrossLogo from "../images/Clients/Salvation.jpg";
import NTCLogo from "../images/Clients/Ntc-main.jpg";
import KnowLogo from "../images/Clients/Knowbe4.jpg";
import David from "../images/Marketing/david.jpg";
import Mike from "../images/Marketing/mike.jpg";
import UET from "../images/Marketing/UET-logo-black.svg";
import { Helmet } from "react-helmet";
import AnchorLink from "react-anchor-link-smooth-scroll";

const MarketingLanding = () => {
  return (
    <>
      <Helmet>
        <title>
          Consult FGC | Digital Marketing Agency | Website Design & Development
        </title>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        ></link>
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://consultfgc.com/"
        ></link>
        <meta data-react-helmet="true" charset="utf-8"></meta>
        <meta
          data-react-helmet="true"
          name="description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          data-react-helmet="true"
          name="robots"
          contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:locale"
          content="en-US"
        ></meta>
        <meta data-react-helmet="true" property="og:type" content="page"></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://consultfgc.com/"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          data-react-helmet="true"
          property="article:tag"
          content="geofencing , geofencing advertising , true-scrope"
        ></meta>
        <meta
          data-react-helmet="true"
          property="face-domain-verification"
          content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
        ></meta>
        <link
          href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
          rel="stylesheet"
        ></link>
        <script
          src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
          async
        ></script>
      </Helmet>

      <div class="hero bg-white">
        <div class="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-8">
          <img
            src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
            alt=""
            class="absolute inset-0 -z-10 h-full w-full object-cover"
          />

          <header class="">
            <nav class="max-w-7xl mx-auto" aria-label="Top">
              <div class="w-full flex items-center justify-between border-b border-indigo-500 lg:border-none">
                <div class="flex items-center">
                  <a href="/">
                    <span class="sr-only">Consult FGC</span>
                    <img
                      class="h-20 w-auto hover:opacity-70"
                      src="https://imgix.cosmicjs.com/b6cf2a70-daa2-11ec-bb19-d9085ce408df-Consult-logo-white.svg"
                      alt=""
                    />
                  </a>
                </div>
                <a
                  href="tel:+18883417735"
                  class="inline-flex items-center gap-x-2 rounded-md bg-blue-700 px-10 py-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 "
                >
                  <svg
                    class="-ml-0.5 h-5 w-5 text-green-400"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                    fill="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                  +1 (888) 341-7735
                </a>
              </div>{" "}
            </nav>
          </header>

          <div class="mx-auto max-w-7xl pt-24 pb-28">
            <div class="mx-auto flex justify-between">
              <div class="mt-10 max-w-4xl ">
                <h1 class="-ml-1 text-4xl font-semibold tracking-tight text-green-400 sm:text-6xl ">
                  <span class="font-bold">Experts</span> . . . In . . .
                  <span class="pt-5 block">
                    Digital <span class="font-bold">Advertising</span> . . .{" "}
                  </span>
                  <span class="pt-5 block">
                    That Get <span class="font-bold">Results</span> . . .
                    Period!{" "}
                  </span>
                </h1>
                <p class="pt-5 text-lg max-w-3xl leading-8 text-white">
                  Finally . . . work with a TEAM of dedicated advertising
                  specialists with over 40+ combined years of experience for a
                  fraction of the cost of hiring. Make it our problem to solve!
                </p>
                <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-xl">
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-300">
                      <svg
                        class="absolute left-0 top-0 h-7 w-7 text-blue-500"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Campaigns not generating leads?
                    </dt>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-300">
                      <svg
                        class="absolute left-0 top-0 h-7 w-7 text-blue-500"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Current agency giving you the “drive-through” treatment?
                    </dt>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-300">
                      <svg
                        class="absolute left-0 top-0 h-7 w-7 text-blue-500"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Cost-per QUALIFIED lead is way to high?
                    </dt>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-300">
                      <svg
                        class="absolute left-0 top-0 h-7 w-7 text-blue-500"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Are you advertising in the right marketing vehicles, with
                      the right messaging, to the right website or landing page?
                    </dt>
                  </div>
                </dl>
              </div>
              <div class="max-w-md bg-gray-200 p-6 rounded-lg">
                {" "}
                <section id="form">
                  <form action="#" method="POST" class=" lg:flex-auto">
                    <div class="pt-6 pb-7 grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                      <div>
                        <label
                          for="first-name"
                          class="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          First name
                        </label>
                        <div class="mt-2.5">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autocomplete="given-name"
                            class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="last-name"
                          class="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Last name
                        </label>
                        <div class="mt-2.5">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autocomplete="family-name"
                            class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="budget"
                          class="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Job Title
                        </label>
                        <div class="mt-2.5">
                          <input
                            id="budget"
                            name="budget"
                            type="text"
                            class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="budget"
                          class="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Phone
                        </label>
                        <div class="mt-2.5">
                          <input
                            type="number"
                            id="budget"
                            name="budget"
                            type="text"
                            class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="website"
                          class="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Business Website
                        </label>
                        <div class="mt-2.5">
                          <input
                            type="url"
                            name="website"
                            id="website"
                            class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="website"
                          class="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Business Email
                        </label>
                        <div class="mt-2.5">
                          <input
                            type="url"
                            name="website"
                            id="website"
                            class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div class="mt-2.5 sm:col-span-2">
                        <label
                          for="website"
                          class="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Monthly Marketing Budget
                        </label>
                        <select
                          type="text"
                          name="referral"
                          id="referral"
                          class="mt-2.5 block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="" selected disabled hidden>
                            Please Select A Budget
                          </option>
                          <option value="Referral">$5,000 - $10,000</option>
                          <option value="Online Ad">$10,000 - $25,000</option>
                          <option value="Streaming Ad">
                            $25,000 - $50,000+
                          </option>
                          <option value="Social Media Ad">
                            NOT SURE HELP ME
                          </option>
                        </select>
                      </div>
                      <div class="sm:col-span-2">
                        <div class="flex justify-between text-sm leading-6">
                          <label
                            for="message"
                            class="block text-sm font-semibold leading-6 text-gray-900"
                          >
                            How can we help you?
                          </label>
                          <p id="message-description" class="text-gray-400">
                            Max 500 characters
                          </p>
                        </div>
                        <div class="mt-2.5">
                          <textarea
                            id="message"
                            name="message"
                            rows="4"
                            aria-describedby="message-description"
                            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2.5">
                      <button
                        type="submit"
                        class="block w-full rounded-md bg-emerald-600 px-3.5 py-4 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Schedule A Free Consultation
                      </button>
                    </div>
                    <p class="mt-4 text-xs italic text-center leading-6 text-gray-500">
                      Note in small font under form submittal button: We do not
                      sell your information and will not overwhelm you with
                      emails.
                    </p>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="testimonials">
        <section class="bg-gradient-to-r from-emerald-100 to-yellow-100 pt-32 pb-32">
          <div class="mx-auto max-w-7xl">
            <h1 class="-ml-1 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl ">
              Don’t Believe Us? Listen To Some{" "}
              <span class="block pt-5">Of Our Clients . . .</span>
            </h1>
            <div class="pt-20 mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div class="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                <img class="h-12 self-start" src={Achieva} alt="" />
                <figure class="mt-10 flex flex-auto flex-col justify-between">
                  <blockquote class="text-lg leading-8 text-gray-900">
                    <p>
                      “I have worked with Consult F.G.C. for over 10 years now.
                      I am consistently amazed at how responsive and
                      professional they are. Their entire team is full of all
                      stars, projects get done, advertising produces results,
                      and I’m kept in the loop always. Can’t recommend them
                      highly enough.""
                    </p>
                  </blockquote>
                  <figcaption class="mt-10 flex items-center gap-x-6">
                    <img
                      class="h-14 w-14 rounded-full bg-gray-50"
                      src={David}
                      alt=""
                    />
                    <div class="text-base">
                      <div class="font-semibold text-gray-900">David Oak</div>
                      <div class="mt-1 text-gray-500">CMO of Achieva</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
              <div class="flex flex-col border-t border-gray-900/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                <img class="h-12 self-start" src={UET} alt="" />
                <figure class="mt-10 flex flex-auto flex-col justify-between">
                  <blockquote class="text-lg leading-8 text-gray-900">
                    <p>
                      “I have worked with Consult F.G.C. for over 8 years. They
                      have been instrumental in the management and advertising
                      of our entire brand portfolio [8 brands]. From website
                      design to robust advertising programs, and even an online
                      APP they can do it all. I can’t recommend Consult F.G.C.
                      enough.”
                    </p>
                  </blockquote>
                  <figcaption class="mt-10 flex items-center gap-x-6">
                    <img
                      class="h-14 w-14 rounded-full bg-gray-50"
                      src={Mike}
                      alt=""
                    />
                    <div class="text-base">
                      <div class="font-semibold text-gray-900">
                        Mike Huggins
                      </div>
                      <div class="mt-1 text-gray-500">
                        Managing Director of UET
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="bg-white pt-14 pb-14">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 class="text-center text-lg font-semibold leading-8 text-gray-700">
            Other clients that enjoy the privilege of our services
          </h2>
          <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <img
              class="col-span-2 w-1/2 mx-auto object-contain lg:col-span-1"
              src={AchievaLogo}
              alt="Achieva"
            />
            <img
              class="col-span-2 w-1/2 mx-auto object-contain lg:col-span-1"
              src={KratosLogo}
              alt="Kratos"
            />
            <img
              class="col-span-2 w-1/2 mx-auto object-contain lg:col-span-1"
              src={CallectiveLogo}
              alt="Kratos"
            />
            <img
              class="col-span-2 w-1/2 mx-auto object-contain lg:col-span-1"
              src={RedCrossLogo}
              alt="Achieva"
            />
            <img
              class="col-span-2 w-1/2 mx-auto object-contain lg:col-span-1"
              src={NTCLogo}
              alt="Kratos"
            />
            <img
              class="col-span-2 w-1/2 mx-auto object-contain lg:col-span-1"
              src={KnowLogo}
              alt="Kratos"
            />
          </div>
        </div>
      </div>

      <div class="bg-gray-900 px-5 md:px-0 services pt-20 pb-20">
        <div class="max-w-7xl mx-auto">
          <h1 class="-ml-1 text-4xl font-semibold tracking-tight text-white text-center sm:text-5xl ">
            Supercharge Your Business
          </h1>
          <p class="mt-4 max-w-2xl mx-auto text-center text-lg text-white">
            Pull back the curtains on digital marketing. We're here to provide
            expert advice to fuel your marketing strategy.
          </p>
          <div class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 lg:mt-16 lg:grid-cols-3 lg:gap-x-12 lg:gap-y-16">
            <div class="branding">
              <div class="bg-white border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-amber-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M22 8.51v1.372h-2.538c.02-.223.038-.448.038-.681 0-.237-.017-.464-.035-.69h2.535zm-10.648-6.553v-1.957h1.371v1.964c-.242-.022-.484-.035-.726-.035-.215 0-.43.01-.645.028zm5.521 1.544l1.57-1.743 1.019.918-1.603 1.777c-.25-.297-.593-.672-.986-.952zm-10.738.952l-1.603-1.777 1.019-.918 1.57 1.743c-.392.28-.736.655-.986.952zm-1.597 5.429h-2.538v-1.372h2.535c-.018.226-.035.454-.035.691 0 .233.018.458.038.681zm9.462 9.118h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm0 2h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm.25 2h-4.5l1.188.782c.154.138.38.218.615.218h.895c.234 0 .461-.08.615-.218l1.187-.782zm3.75-13.799c0 3.569-3.214 5.983-3.214 8.799h-1.989c-.003-1.858.87-3.389 1.721-4.867.761-1.325 1.482-2.577 1.482-3.932 0-2.592-2.075-3.772-4.003-3.772-1.925 0-3.997 1.18-3.997 3.772 0 1.355.721 2.607 1.482 3.932.851 1.478 1.725 3.009 1.72 4.867h-1.988c0-2.816-3.214-5.23-3.214-8.799 0-3.723 2.998-5.772 5.997-5.772 3.001 0 6.003 2.051 6.003 5.772z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    Branding Strategy
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    Establish a new online identity for your business or
                    revitalize an old one. We can help you from start to finish.
                  </p>
                </div>
              </div>
            </div>
            <div class="web design">
              <div class="bg-white border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-purple-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M24 23h-20c-2.208 0-4-1.792-4-4v-15.694c.313-1.071 1.285-2.306 3-2.306 1.855 0 2.769 1.342 2.995 2.312l.005 1.688h18v18zm-2-16h-16v11s-.587-1-1.922-1c-1.104 0-2.078.896-2.078 2s.896 2 2 2h18v-14zm-2 12h-12v-10h12v10zm-8-9h-3v8h10v-8h-6v3h6v1h-2v3h-1v-3h-3v3h-1v-7z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    Web Design & Development
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    In-house experts ready to build out custom designs &
                    functionality.
                  </p>
                  <p class="opacity-0">-</p>
                </div>
              </div>
            </div>

            <div class="sem">
              <div class="bg-white border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-lime-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M20.699 11.069c1.947 1.015 3.3 2.81 3.3 4.977 0 1.204-.433 2.387-1.202 3.304-.032 1.075.601 2.618 1.171 3.741-1.529-.277-3.702-.886-4.686-1.49-.833.202-1.631.295-2.385.295-2.919 0-5.185-1.398-6.278-3.271 6.427-.207 9.818-4.482 10.08-7.556zm-10.798-10.07c-5.282 0-10 3.522-10 8.34 0 1.708.615 3.385 1.705 4.687.046 1.525-.851 3.713-1.66 5.307 2.168-.392 5.251-1.257 6.648-2.114 7.696 1.873 13.307-2.837 13.307-7.88 0-4.844-4.751-8.34-10-8.34zm3.048 10.74c-.212.071-.441-.044-.511-.256 0 0-1.496-.501-3.272.088l1.166 1.814c.121.179.037.422-.164.49l-.794.263-.167.028c-.149 0-.294-.064-.396-.179l-1.604-1.77c-.873.276-1.862-.042-2.192-.865-.076-.188-.114-.389-.114-.592 0-.585.327-1.183 1.038-1.533 3.559-1.747 4.128-3.696 4.128-3.696-.086-.262.11-.532.382-.532.169 0 .327.108.383.277l2.371 5.952c.071.212-.044.441-.254.511zm1.687-2.043l-.614-.261c.267-.634.293-1.371.014-2.058-.279-.688-.808-1.199-1.441-1.466l.26-.615c1.265.535 2.046 1.771 2.046 3.091 0 .454-.092.898-.265 1.309zm-1.063-.451l-.599-.254c.154-.365.169-.789.008-1.185-.16-.396-.466-.69-.83-.843l.253-.601c.518.219.952.635 1.179 1.198.229.564.207 1.165-.011 1.685z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    SEM & Digital Marketing
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    We’ll evaluate how to best meet your marketing needs in an
                    online world and get to work.
                  </p>
                </div>
              </div>
            </div>
            <div class="social">
              <div class="bg-white border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-blue-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    Social Media & Marketing
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    Social media is more than just a buzzword. We delve into
                    effective strategies to make social work for you.
                  </p>
                </div>
              </div>
            </div>
            <div class="geofence">
              <div class="bg-white border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-cyan-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M18 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.852-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm-12-3c-2.099 0-4 1.702-4 3.801 0 3.121 3.188 3.451 4 8.199.812-4.748 4-5.078 4-8.199 0-2.099-1.901-3.801-4-3.801zm0 5.333c-.737 0-1.333-.597-1.333-1.333s.596-1.333 1.333-1.333 1.333.596 1.333 1.333-.596 1.333-1.333 1.333zm6 5.775l-3.215-1.078c.365-.634.777-1.128 1.246-1.687l1.969.657 1.92-.64c.388.521.754 1.093 1.081 1.75l-3.001.998zm12 7.892l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 3.62-8.144c.299.76.554 1.776.596 3.583l-.443.996 2.699-1.021 4.809 2.091.751-3.725.718 3.675 4.454-2.042 3.099 1.121-.461-1.055c.026-.392.068-.78.131-1.144.144-.84.345-1.564.585-2.212l3.442 7.877z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    Geofence Advertising
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    Add hyperlocal marketing to your marketing tool box with our
                    advanced geofencing platform.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="more">
              <div class="bg-white border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-red-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-3 11.25c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75zm-11.772-.537c-.151-.135-.228-.321-.228-.509 0-.375.304-.682.683-.682.162 0 .324.057.455.173l.746.665 1.66-1.815c.136-.147.319-.221.504-.221.381 0 .684.307.684.682 0 .163-.059.328-.179.459l-2.116 2.313c-.134.147-.319.222-.504.222-.162 0-.325-.057-.455-.173zm11.772-2.711c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75zm-11.772-1.613v.001c-.151-.135-.228-.322-.228-.509 0-.376.304-.682.683-.682.162 0 .324.057.455.173l.746.664 1.66-1.815c.136-.147.319-.221.504-.221.381 0 .684.308.684.682 0 .164-.059.329-.179.46l-2.116 2.313c-.134.147-.319.221-.504.221-.162 0-.325-.057-.455-.173zm11.772-1.639c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">& More</h3>
                  <p class="mt-2 text-base text-gray-600">
                    We are a full-service, digital agency. Contact us to build a
                    custom plan for your company.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-20 mb-8 ">
          <AnchorLink
            href="#form"
            class="inline-flex items-center gap-x-2 rounded-md bg-blue-700 px-20 py-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 "
          >
            Get Started Today
          </AnchorLink>
        </div>
      </div>

      <footer class="bg-white py-3 border-t border-gray-100">
        <div class="text-center md:text-left py-5 max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div class="">
            <p class="pb-2 text-base text-gray-400">Get In Touch</p>
            <h1 class="text-4xl tracking-wide font-bold text-primaryBlue">
              <a class="hover:underline" href="tel:8883417735">
                {" "}
                888.341.7735
              </a>
            </h1>
            <h1 class="mt-3 mb-10 md:mb-0 md:text-3xl tracking-wide font-medium text-teal-500">
              <a class="hover:underline" href="mailto:marketing@consultfgc.com">
                {" "}
                marketing@consultfgc.com
              </a>
            </h1>
          </div>
          <div class="flex items-center justify-center">
            <div class="relative">
              {" "}
              <a
                class="w-2/3 block hover:opacity-70"
                href="https://www.google.com/partners/agency?id=4727175905"
                target="_blank"
              >
                <img
                  class=""
                  src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg"
                />
              </a>
            </div>
            <div class=" -ml-6 relative">
              {" "}
              <a
                class="hover:opacity-70"
                href="https://ugecredits.com/"
                target="_blank"
              >
                <img
                  class="w-24 block"
                  src="https://imgix.cosmicjs.com/d7d9c380-f224-11ec-a2eb-c1653f3f9199-Circle-badge.svg"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="copyright border-gray-100 py-5  border-t max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div class="flex justify-center space-x-6 md:order-2">
            <a
              href="https://www.facebook.com/ConsultFGC/"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Facebook</span>
              <svg
                class="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            <a
              href="https://www.linkedin.com/company/consult-fgc/"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Linkedin</span>
              <svg
                class="h-6 w-6 pt-0.5 pb-0.5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>

          <div class="mt-8 md:mt-0 md:order-1">
            <p class="text-center text-base text-gray-400">
              &copy; {new Date().getFullYear()} ConsultFGC,
              <a class="text-blue-400 hover:underline" href="/capabilities">
                <span> GSA Capabilities</span>
              </a>{" "}
            </p>
          </div>
        </div>

        <div class="text-center space-x-2 md:text-left max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center lg:px-8">
          <a href="/terms-of-service">
            <span class="text-gray-400 hover:text-blue-400 hover:underline">
              Terms and Conditions
            </span>
          </a>
          <a href="/ccpa">
            <span class="text-gray-400 hover:text-blue-400 hover:underline">
              {" "}
              CCPA
            </span>
          </a>
          <a href="/privacy-policy">
            <span class="text-gray-400 hover:text-blue-400 hover:underline">
              {" "}
              Privacy Policy{" "}
            </span>
          </a>
        </div>
      </footer>
    </>
  );
};

export default MarketingLanding;
